import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import PersonalizationMessageBar from "../../components/notifications/personalization-message-bar";
import Icon from "../../components/custom-widgets/icon";
import Button from "../../components/custom-widgets/button";
import List from "../../components/custom-widgets/list";
import Tab from "../../components/tab/custom-tab";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const OpenBankAccountOnline = () => {
  const title = "Open a Bank Account Online Instantly";
  const description =
    "Open a bank account online with WaFd Bank. We offer online savings accounts, online checking accounts and many perks for our customers including over 37, 000 fee free ATMs.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "open-bank-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Couple in coffee shop looking at cell phone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Easily Open a New Personal WaFd Bank Account Online in Minutes"
          }
        }
      ]
    }
  };

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/open-bank-account-online"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-open-an-account-013023.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: "true",
      title: "Open Bank Account Online"
    }
  ];

  const freeCheckingListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Only $25 initial deposit to open"
      },
      {
        id: 2,
        text: "No minimum balance"
      },
      {
        id: 3,
        text: "No monthly maintenance fees*"
      },
      {
        id: 4,
        text: "Access to thousands of Fee-free ATMs"
      },
      {
        id: 5,
        text: "Online Banking"
      },
      {
        id: 6,
        text: "Bill Pay & external transfers"
      },
      {
        id: 7,
        text: "Mobile Banking"
      },
      {
        id: 8,
        text: "Person-to-person payments"
      },
      {
        id: 9,
        text: "Overdraft Protection"
      },
      {
        id: 10,
        text: "Secure Checking"
      }
    ]
  };

  const InterestCheckingListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Only $25 initial deposit to open"
      },
      {
        id: 2,
        text: 'Get a Free 3x5" Safe Deposit Box (where available)'
      },
      {
        id: 3,
        text: "5 ¢/gal Shell Fuel Savings"
      },
      {
        id: 4,
        text: "24/7 Telehealth Services"
      },
      {
        id: 5,
        text: "Identity Theft, Credit File and Dark Web Monitoring"
      },
      {
        id: 6,
        text: "Up to $1 million Identity Theft Reimbursement"
      },
      {
        id: 7,
        text: "Up to $800 per claim on Mobile Protection ($50 deductible applies)"
      },
      {
        id: 8,
        text: "24-Hour Roadside Assistance"
      },
      {
        id: 9,
        text: "Rideshare Protection"
      },
      {
        id: 10,
        text: "Accidental Death & Dismemberment (AD&D) Insurance"
      },
      {
        id: 11,
        text: "Extended Warranties and Buyer's Protection"
      },
      {
        id: 12,
        text: "Health, Shopping, Travel and Leisure discounts"
      }
    ]
  };

  const savingsListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Free for minor accounts up to age 18."
      },
      {
        id: 2,
        text: "Only $25 initial deposit ($10 for minors)"
      },
      {
        id: 3,
        text: "No monthly fee ($3 Per Month for balances below $100)"
      },
      {
        id: 4,
        text: 'Minimum balance to earn interest is $100. ($10 for minors); this is a variable-rate account <a href="/personal-banking/interest-rates" id="open-acct-online-rates-link">See interest rates/APY</a>'
      }
    ]
  };

  const highYieldListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Only $25 to Open"
      },
      {
        id: 2,
        text: "This is a variable-rate account"
      },
      {
        id: 3,
        text: "Product Effective 10/19/2022. Promotion may be discontinued at any time"
      },
      {
        id: 4,
        text: "Not available for IRAs or Business clients"
      }
    ]
  };

  const FreeCheckingData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../../images/thumbnail-free-checking-050624.jpg"
        alt="Group of friends enjoying a carefree sunset at a lake"
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3 mb-4 mb-sm-0">
        <h2>Free Checking</h2>
        <h4 className="text-success font-weight-bold" id="fc-snippet">
          Get the Best *Free Checking Account Around
        </h4>
        <p className="font-weight-bold">Everything you want in a free checking account:</p>
        <List {...freeCheckingListData} accountName={"free-checking"} />
        <p className="font-weight-bold">
          <Link to="/personal-banking/free-checking-account" id="fc-learn-more" className="text-decoration-none">
            Learn more about Free Checking
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="free-checking-open-account-cta"
          text="Open a Free Checking Account"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted" id="fc-fine-print">
          *Nonsufficient Funds Charge may apply
        </p>
      </div>
    </>
  );

  const InterestCheckingData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../../images/thumbnail-page-interest-checking-050624.jpg"
        alt="Shopping man smiling while on his phone"
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3 mb-4 mb-sm-0">
        <h2>Interest Checking</h2>
        <h4 className="text-success font-weight-bold" id="sc-snippet">
          Interest, Rewards and More, All in one Account
        </h4>
        <p className="font-weight-bold">Enjoy access to benefits valued at $95+ every month!</p>
        <List {...InterestCheckingListData} accountName={"interest-checking"} />
        <p className="font-weight-bold">
          <Link
            to="/personal-banking/checking-account/interest-rewards-checking"
            id="sc-learn-more"
            className="text-decoration-none"
          >
            Learn more about Interest Checking
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="interest-open-account-cta"
          text="Open an Interest Checking Account"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted" id="sc-fine-print">
          *At branches where available
        </p>
      </div>
    </>
  );

  const SavingsData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../../images/cards/card-savings.jpg"
        alt="Young couple standing in the rain under an umbrella"
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3 mb-4 mb-sm-0">
        <h2>Savings</h2>
        <h4 className="font-weight-bold text-success" id="savings-snippet">
          Open a savings account with the tools and services to help you prepare for the future.
        </h4>
        <p className="font-weight-bold">Everything you need in a Savings account:</p>
        <List {...savingsListData} accountName={"savings"} />
        <p className="font-weight-bold">
          <Link to="/personal-banking/savings-account" id="savings-learn-more" className="text-decoration-none">
            Learn more about Savings
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="savings-open-account-cta"
          class="btn-primary"
          text="Open a Savings Account"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted" id="savings-no-credit-run">
          WaFd Bank will not run your credit for this application.<sup>1</sup>
        </p>
      </div>
    </>
  );

  const HighYieldMoneyMarketData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../../images/cards/card-money-market.jpg"
        alt="Mature couple standing with their surfboards at the beach"
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3 mb-4 mb-sm-0">
        <h2>High Yield Money Market</h2>
        <h4 className="font-weight-bold text-success" id="mm-snippet">
          The tools and services to help you build wealth with confidence
        </h4>
        <p className="font-weight-bold">Flexibility to manage your money:</p>
        <List {...highYieldListData} accountName={"high-yield-money-market"} />
        <p className="font-weight-bold">
          <Link to="/personal-banking/savings-account" id="mm-learn-more" className="text-decoration-none">
            Learn more about Money Market Accounts
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="high-yield-money-market-open-account-cta"
          class="btn-primary"
          text="Open a Money Market Account"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted" id="mm-no-credit-run">
          WaFd Bank will not run your credit for this application.<sup>1</sup>
        </p>
      </div>
    </>
  );

  const quickLinksData = [
    {
      name: "Mortgage Loans",
      text: "WaFd Bank is a portfolio lender that will be with you every step.",
      imageSource: "/images/icons/mortgage-loans-icon-96.svg",
      altText: "Mortgage Loans Link.",
      url: "/personal-banking/home-loans"
    },
    {
      name: "Home Equity Loans",
      text: "Use your home's equity to finance your next big project.",
      imageSource: "/images/icons/heloc-loans-icon-96.svg",
      altText: "Home Equity Loans Link.",
      url: "/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
    },
    {
      name: "Credit Cards",
      text: "Get Competitive Rates and Great Rewards with WaFd Credit Cards.",
      imageSource: "/images/icons/credit-cards-icon-96.svg",
      altText: "Credit Cards Link.",
      url: "/personal-banking/credit-cards"
    },
    {
      name: "Business Loans",
      text: "Make the most of your money with our small business banking solutions.",
      imageSource: "/images/icons/business-loans-icon-96.svg",
      altText: "Business Loans Link.",
      url: "/business-banking/small-business"
    }
  ];

  const SavingsTabData = () => (
    <>
      <div className="row row-cols-1 row-cols-sm-2">
        <div className="col-sm-6 card border-0 mb-5" id="savings-data-column">
          <div className="bg-light h-100 border-radius-12">
            <SavingsData />
          </div>
        </div>
        <div className="col-sm-6 card border-0 mb-5" id="high-yield-money-market-data-column">
          <div className="bg-light h-100 border-radius-12">
            <HighYieldMoneyMarketData />
          </div>
        </div>
      </div>

      <p className="text-muted text-sm" id="savings-no-credit-pull-1">
        <sup>1</sup>For account opening WaFd Bank does not pull your credit from one of the three main credit bureaus.
        We will review OFAC and ChexSystems<sup>&reg;</sup>.
      </p>

      <div className="row">
        <div className="col mb-3">
          <div className="d-flex flex-column flex-sm-row align-items-start">
            <Link
              className="btn btn-link w-100 w-sm-auto mb-3 mb-sm-0 mr-0 mr-sm-3"
              to="/personal-banking/savings-account"
              id="mm-more-accounts"
            >
              More Savings Accounts Options
            </Link>

            <Link
              className="btn btn-link w-100 w-sm-auto"
              to="/personal-banking/savings-account/certificate-of-deposit"
              id="certificates-of-deposit"
            >
              Certificates of Deposit (CDs)
            </Link>
          </div>
        </div>
      </div>
    </>
  );

  const CheckingTabData = () => (
    <>
      <div className="row row-cols-1 row-cols-sm-2">
        <div className="col-sm-6 card border-0 mb-5" id="free-checking-data-column">
          <div className="bg-light h-100 border-radius-12 ">
            <FreeCheckingData />
          </div>
        </div>
        <div className="col-sm-6 card border-0 mb-5" id="interest-checking-data-column">
          <div className="bg-light h-100 border-radius-12 ">
            <InterestCheckingData />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col mb-3 text-sm-left">
          <Link className="btn btn-link w-100 w-md-auto" to="/personal-banking/checking-account" id="fc-more-accounts">
            More Checking Accounts Options
          </Link>
        </div>
      </div>
    </>
  );

  const tabComponentData = {
    tabs: [
      { name: "Checking Accounts", data: <CheckingTabData /> },
      {
        name: "Saving Accounts",
        data: <SavingsTabData />
      }
    ],
    defaultActiveTabIndex: 0
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <PersonalizationMessageBar />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Open a Bank Account Online</h1>
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-gray-80">
              Are you looking to open a checking or savings account, or add to your existing accounts? WaFd Bank, named
              Best Big Bank by Newsweek three years in a row, offers top-rated checking accounts you can open in just
              minutes.
            </h4>

            <p className="font-weight-bold text-gray-80">
              We also offer <Link to="/business-banking/small-business">Small Business</Link> and{" "}
              <Link to="/commercial-banking">Commercial</Link> accounts.
            </p>
          </div>
          <div className="col-md-6">
            <h5>Here's what you'll need</h5>
            <ul>
              <li>Live in Arizona, California, Idaho, Nevada, New Mexico, Oregon, Texas, Utah, or Washington.</li>
              <li>Funding method, such as a credit or debit card, or routing and account number.</li>
            </ul>
            <h4 className="font-weight-bold mb-0">Have Questions?</h4>
            <h4 className="mb-0">
              See our{" "}
              <Link id="account-opening-faq-link" to="/open-bank-account-online/account-opening-faq">
                Account Opening FAQs
              </Link>
              .
            </h4>
          </div>
        </div>
      </section>

      <Tab {...tabComponentData} />

      <section className="bg-light" id="quick-links-section">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
            {quickLinksData.map((block, index) => {
              return (
                <div
                  key={index}
                  id={block.name.toLocaleLowerCase().replace(/ +/g, "-")}
                  className="text-center mb-3 px-2"
                >
                  <img src={block.imageSource} alt={block.altText} className="mb-3" />
                  <h3>{block.name}</h3>
                  <Link
                    to={block.url}
                    id={block.name.toLocaleLowerCase().replace(/ +/g, "-") + "-link"}
                    className="text-center mb-3 mb-lg-0 text-black text-decoration-none"
                  >
                    <span dangerouslySetInnerHTML={{ __html: block.text }} />
                    <Icon name="arrow-right" class="ml-1 text-primary" />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default OpenBankAccountOnline;
